import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

export default ({ data, location, pageContext: { c: course } }) => {
  const siteTitle = data.site.siteMetadata.title
  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={`${course.name}`} />
      <h4>{course.name}</h4>
      <Link to="/courses/">View all courses</Link>
      <div className="flex-column-start course-table-content">
        {course.posts.map(({ node }, i) => (
          <div key={i} className="course-module">
            <Link
              to={node.fields.slug}
              state={{
                prevLocation: {
                  url: `${location.pathname}/`,
                  label: course.name,
                },
              }}
            >
              {i + 1}. {node.frontmatter.title}
            </Link>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        popularTopics
      }
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { course: { ne: null } } }
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          html
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            tags
            author
          }
        }
      }
    }
  }
`
